import { inject } from 'vue';

import { subscribeKey, unsubscribeKey } from '@drapejs/core';

export default function (options) {
  const subscribe = inject(subscribeKey, () => Promise.resolve());
  const unsubscribe = inject(unsubscribeKey, () => Promise.resolve());

  const handler = (data) => {
    const products = data?.relatedProducts?.products || [];
    options.setProducts(products);
  };

  const registerRelatedProductsBlock = (block) => {
    if (hasRelationshipType(block)) {
      subscribe(getRelatedProductsCacheKey(block), handler, {
        immediate: true,
      });
    }
  };

  const unregisterRelatedProductsBlock = (block) => {
    if (hasRelationshipType(block)) {
      unsubscribe(getRelatedProductsCacheKey(block), handler);
    }
  };

  return {
    registerRelatedProductsBlock,
    unregisterRelatedProductsBlock,
  };
}

export function getRelatedProductsCacheKey(block) {
  return `products:block:${block.systemId}`;
}

export function hasRelationshipType(block) {
  return block?.fields?.RelationshipType;
}
